import Vue from 'vue'
import Bootstrap, { plugins, globals, uses, router, filters } from './Bootstrap'
// 定义全局变量
// for(let g in global) {
//   Vue.prototype[g] = global[g]
// }

async function initApp() {
  const g = await globals()
  for (let key in g) {
    if(key === '$loading') {
      g[key](Vue)
    } else {
      Vue.prototype[key] = g[key]
      if(key === '$http') {
        Vue.prototype[key].prototype.$router = router
      }
    }
  }
  Vue.prototype.$projectName="Quick Order"
  const u = await uses();
  for (let key in u) {
    Vue.use(u[key])
  }
  const f = await filters();
  for (let key in f) {
    
    Vue.filter(key, f[key])
  }

  const p = await plugins(Vue)
  const app = new Vue({
    ...p,
    render: h => h(Bootstrap)
  })
  app.$mount('#app')
  // window.__app = app
}

initApp(Vue)

// globals().then(g => {
//   for (let key in g) {
//     Vue.prototype[key] = g[key]
//   }
// })
// uses().then(g => {
//   for (let key in g) {
//     Vue.use(g[key])
//   }
// })

// plugins().then(plugin => {
//   const app = new Vue({
//     ...plugin,
//     render: h => h(Bootstrap)
//   })
//   app.$mount('#app')
//   window.app = app
// })
// 本地引入mock
// if ( _ENV_.NODE_ENV === 'local') {
//   require(/* webpackIgnore */'./mock/index')
// }

