import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { apps } from '@/config/Apps'

Vue.use(Vuetify)

export default async function instanceVuetify() {
  let i18n = {}, mi18n = {};
  for (let app in apps) {
    mi18n = await apps[app].i18n()
    // console.log(mi18n, app, 1212)

    if (mi18n) {
      const lang = Object.keys(mi18n);
      for (let langName of lang) {
        if (!i18n.hasOwnProperty(langName)) {
          i18n[langName] = mi18n[langName]
        } else {
          i18n[langName] = { ...i18n[langName], ...mi18n[langName] }
        }
      }
    }
  }
  // console.log(i18n, app, 1212)

  const allLangs = Object.keys(i18n)
  const currentLang = allLangs.includes('en') ? 'en' : (allLangs[0] ? allLangs[0] : 'en')
  const opts = {
    breakpoint: {
      thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920,
      },
      mobileBreakpoint: 'sm' // 这个值等于960
    },
    icons: {
      iconfont: 'mdi', // 默认值 - 仅用于展示目的
    },
    lang: {
      locales: i18n,
      current: currentLang,
    },
  }
  return new Vuetify(opts)
}
