import axios from 'axios'
// const baseUrl = _ENV_.SOURCE.LOGIN_URL
// import {loginUrl} from '../api/index'
const http = async () => {
  const { http } = await import('library/Bootstrap')
  const instance = axios.create({
    'Content-Type': 'application/json;charset=UTF-8',
  })
// async function responseUse(res) {
//   if(res.data && res.data.hasOwnProperty('access_token')) {
//     localStorage.setItem('auth-token', JSON.stringify(res.data))
//     localStorage.setItem('access-token', JSON.stringify(res.data.access_token))
//     const data = {
//       data:res.data,
//       code: "200"
//     }
//     return data
//   }
//   if(res.data.code === '408') {
//       // 登录过期
//       const token = JSON.parse(localStorage.getItem('auth-token'))
//       localStorage.removeItem('access-token')
//       const res = await instance.post(loginUrl, {
//         grant_type: 'refresh_token',
//         refresh_token: token.refresh_token,
//         client_id: 'quotation',
//         client_secret: 'quotation'
//       })
//       if(res.data && res.data.hasOwnProperty('access_token')) {
//         localStorage.setItem('auth-token', JSON.stringify(res.data))
//         localStorage.setItem('access-token', res.data.access_token)
//       }
//       return res
//   }
//   return res
// }
  // http.instance = instance;
  const $http = new http({ instance })
  // console.log(http)
  return $http;
}

export default http