/**
 * author: Grady
 * 说明： preload：表示是否预加载模块，所有预加载的模块将显示在同一个列表中，非预加载的模块将以动态方式加载并切换
 * email: shixiang@skystartrade.com
 * last updated date: 6.25.2021
 */
import {libConfig, appsConfig} from "./entry.config.js"

const config = [...appsConfig, ...libConfig];
for (let i in config) {
  config[i].name = config[i].name.toLowerCase();
}
export default config;
