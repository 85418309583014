<template>
  <v-snackbar
    v-model="snackbar"
    :color="type"
    top
    style="padding-top: 50px"
    :timeout="timeout"
    @input="handleInput"
  >
    <v-icon>{{type === 'info' ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}</v-icon>
    <slot></slot>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="closeMessage">
        <v-icon size="14">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'SMessage',
  props: {
    type: {
      validator(val){
        return ['error','info'].includes(val)
      },
      default: 'info'
    },
    timeout: {
      type: Number,
      default: 2000
    },
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      snackbar: this.value
    }
  },
  watch:{
    value(val) {
      this.snackbar = val
    }
  },
  methods: {
    handleInput(val) {
      this.$emit('input', val)
    },
    closeMessage(){
      this.snackbar = false
      this.$emit('input', false)
    }
  },
};
</script>