import appConfig from './Config'
const config = [
  ...appConfig,
]

const library = function slibraryBootstrap() {
  return import('library/Bootstrap')
}

async function mergeGlobalConfig(type) {
  let g = {}
  for (let app of config) {
    const gs = await app.entry();
    g = { ...g, ...gs[type] }
  }
  return g
}

const globalConfig = {
  uses: mergeGlobalConfig('uses'),
  globals: mergeGlobalConfig('globals'),
  plugins: mergeGlobalConfig('plugins'),
  filters: mergeGlobalConfig('filters')
}

const __http = async () => {
  const module = await library()
  return module.http
}

const apps = {}

for (let app of config) {
  apps[app.name] = {
    name: app.name,
    label: app.label,
    show: app.show,
    icon: app.icon,
    routes: async () => {
      console.log("comin ?")
      const module = await app.entry()
      return module.routes
    },
    store: async () => {
      const module = await app.entry()
      return module.store
    },
    httpConfig: async (instance) => {
      const module = await app.entry()
      return module.httpConfig(instance)
    },
    i18n: async() => {
      const module = await app.entry()
      return module.i18n
    }
  }
}

export { apps, __http, globalConfig, library }


