var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticStyle: { "margin-left": "20px", "margin-top": "10px" } },
      [_c("a", { on: { click: _vm.backToLogin } }, [_vm._v("← Back")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: { width: "60%", "min-width": "1000px", margin: "0 auto" },
      },
      [
        _c("h1", { staticStyle: { "text-align": "center", margin: "20px" } }, [
          _vm._v("Return Policy"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "faq-list" }, [
          _c("ol", [
            _c("li", [
              _c("strong", [
                _vm._v("Parts - "),
                _c("u", [_vm._v("Lifetime Warranty")]),
                _vm._v(" (starting from October 2024)"),
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "All the parts that we sell come with a lifetime warranty."
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Warranty will be voided if parts "),
                  _c("span", [
                    _vm._v("contain any physical or internal damages"),
                  ]),
                  _vm._v("."),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "All qualified return parts will be credited back to customer's wallet account within 2 weeks."
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("We "),
                  _c("u", [_vm._v("do not exchange parts unless")]),
                  _vm._v(
                    " requested by the customer. Highly recommend to place new\n              order for parts you still need while they are being returned for wallet credits.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Parts with installation marks or cosmetic flaws (e.g. scratches, smudges or any signs of wear and\n              tear) may take up to 3 months to get final inspection result from our aftersales facility.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Warranty will not be eligible if returning a package without a valid RMA #."
                  ),
                  _c("br"),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      "How\n              store/wallet credit is calculated:"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ol", [
                    _c("li", [
                      _vm._v(
                        "Items returned within 90 days will receive full credit. Otherwise, a 10% restocking fee will be\n                  applied.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "The (full) credit amount of the return item will be based on the order price paid or the most\n                  up-to-date selling price (whichever is lower)."
                      ),
                      _c("br"),
                      _c("br"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [
                _vm._v("Accessories & Batteries - 90-day warranty"),
                _c("br"),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [
                _vm._v("Tools & Machines - 30-day warranty"),
                _c("br"),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [_vm._v("Return Shipping")]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Customer will be responsible for shipping cost when returning items to us, except on items that were\n              incorrectly sent by us.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "VIP customers will be qualified for a free return shipping label once per month."
                  ),
                  _c("br"),
                  _c("br"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [_vm._v("Special Returns")]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _c("strong", [
                    _vm._v("DOA (Dead on Arrival) - Must report within 5 days"),
                    _c("br"),
                  ]),
                  _vm._v(
                    "If the shipment box was dented\n              or damaged when you received it, please check all parts immediately. If there are any physical damages,\n              please provide us with:"
                  ),
                  _c("br"),
                  _vm._v("Pictures of the front and back of the shipment box."),
                  _c("br"),
                  _vm._v(
                    "Pictures of the front\n              and back of the item that has physical damages.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("strong", [
                    _vm._v(
                      "Customer ordered wrong parts or parts you do not need"
                    ),
                    _c("br"),
                  ]),
                  _vm._v(
                    "A 10% restocking fee will be\n              charged on items that were mistakenly ordered or is no longer needed.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("strong", [_vm._v("We sent the wrong parts"), _c("br")]),
                  _vm._v(
                    "If you received any incorrect items, please send us\n              pictures of both the wrong and correct items if possible (for comparison purposes). Email them along\n              with the order # to "
                  ),
                  _c("a", { attrs: { href: "mailto:Ca@SstParts.com" } }, [
                    _vm._v("Ca@SstParts.com"),
                  ]),
                  _vm._v(
                    " and we will provide a\n              free return shipping label in such circumstances."
                  ),
                  _c("br"),
                  _c("br"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("strong", [_vm._v("Unclaimed return items or packages")]),
              _vm._v(
                " will be discarded after storing for a period of three\n          months.\n        "
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }