// 主要存放一些辅助方法
// 首字母大写
export function toFirstUpper(str) {
    if (!str) return '';
    const strs = str.split("")
    return strs[0].toUpperCase() + strs.slice(1).join("")
}
// 驼峰转换
export function toCamelCase(str, delimiter = ' ', isEnd = false) {
    if (!str) return '';
    const strs = str.split(delimiter)
    const uppers = strs.map(str => {
        return toFirstUpper(str)
    });
    let result = uppers.join(delimiter);

    if (result.indexOf('-') !== -1 && !isEnd) {
        return toCamelCase(result, '-', true)
    }
    return result
}

// 路由子组件自动注入

export function mergeComponentToRoute(routes, components = null) {
    // if(components === null) return routes;
    if (!routes) return [];
    if (!Array.isArray(routes)) {
        throw new Error('routes must be a array,' + typeof routes + ' given');
    }

    if ( typeof components !== 'object' || (!components.hasOwnProperty('customMenus') && !components.hasOwnProperty('customApps'))) {
        return routes;
    }
    return routes.map(route => {
        if (route.children) {
            route.children.forEach(child => {
                child.components = {
                    default: child.component,
                }
                components.hasOwnProperty('customMenus') && (child.components['customMenus'] = components.customMenus)
                components.hasOwnProperty('customApps') && (child.components['customApps'] = components.customApps)
                // delete child.component
            })
        }
        return route
    })
}


// 节流
function throttle(fn, delay) {
    var timer;
    return function () {
        var _this = this;
        var args = arguments;
        if (timer) {
            return;
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
            timer = null; // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
        }, delay)
    }
}

// 防抖
function debounce(fn, delay) {
    var timer; // 维护一个 timer
    return function () {
        var _this = this; // 取debounce执行作用域的this
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            fn.apply(_this, args); // 用apply指向调用debounce的对象，相当于_this.fn(args);
        }, delay);
    };
}

// 深度遍历搜索 data 必须 是一个具有根节点的对象
// data: Object, keyword: String, fn:自定义查找关键词方法，返回Boolea值
export function deepMapSearch(data, keyword, fn) {
    if (!keyword) return data
    let hasKeyword = fn;
    if (fn === undefined) {
        hasKeyword = (keyword, sourceString) => {
            return sourceString.toLowerCase().indexOf(keyword) !== -1
        }
    }
    let line = data["children"], flatArray = []
    // 扁平化树
    while (line.length > 0) {
        let item = line.pop()
        flatArray.push(item)
        if (item.hasOwnProperty('children') && item.children) {
            Array.prototype.push.apply(line, item.children)
        }
    }
    let brother = {
        list: [
        ]
    }
    let children = []
    // 按顺序搜索
    while (flatArray.length > 0) {
        let item = flatArray.pop()
        let lastItem = flatArray[flatArray.length - 1]
        // 找到兄弟节点，则加入到brother里面
        if (lastItem === undefined) {
            if (hasKeyword(keyword, item.name) || item.children && item.children.length > 0) {
                children.push(item)
            }
            data.children = children
            continue
        }
        if (item.pid === 0) {
            if (hasKeyword(keyword, item.name) || item.children && item.children.length > 0) {
                children.push(item)
            }
            continue
        }
        if (item.pid === lastItem.pid && item.pid !== 0) {
            if (hasKeyword(keyword, item.name)) {
                brother.list.push(item)
            }
            continue
        }

        // 找到父节点，则修改父节点的children,同时，把父节点移动到爷爷节点后面
        if (item.pid === lastItem.id) {
            if (hasKeyword(keyword, item.name)) {
                // children.push(item)
                brother.list.push(item)
            }
            lastItem.children = brother.list
            brother.list = []
        } else {
            flatArray.push(item)
            lastItem = item
        }

        let hasParent = false
        // brother.pid = ""
        for (let i = flatArray.length - 1; i > 0; i--) {
            if (flatArray[i - 1].id === lastItem.pid) {
                flatArray[i] = lastItem
                hasParent = true
                break;
            } else {
                flatArray[i] = flatArray[i - 1]
            }
        }
        if (!hasParent) {
            flatArray[0] = lastItem
        }
    }
    return data
}

// 递归遍历
export function recurrenceMap(data, fn) {
    for (let i in data) {
        if (data[i].hasOwnProperty('children')) {
            if (typeof fn === 'function') {
                data[i] = fn(data[i])
            }
            recurrenceMap(data[i].children, fn)
        } else {
            if (typeof fn === 'function') {
                data[i] = fn(data[i])
            }
        }
    }
    return data
}
// 寻找树形父级节点,tree必须 是一个具有根节点 的对象
export function findParentNode(selfId, tree) {
    if (!selfId) return;
    let line = tree['children'], parentNode, flatArray = [];
    while (line.length > 0) {
        let item = line.pop()
        flatArray.push(item)
        if (item.hasOwnProperty('children') && item.children) {
            Array.prototype.push.apply(line, item.children)
        }
    }
    // console.log(flatArray, 188)
    let pid;
    while (!parentNode && flatArray.length > 0) {
        let node = flatArray.pop()
        // debugger
        if (node.id === selfId) {
            pid = node.pid
        }
        if (pid === node.id) {
            parentNode = node
        }
    }
    return parentNode
}

export function trim(val) {
    if (typeof val !== 'string') return ''
    return val.replace(/^\s*|\s*$/g, '')
}

// 检测判断非空
export function isEmpty(val) {
    return null === val || trim(val) === ''
}

// 日期转换
// 需要支持的格式： YY YYYY mm dd h i s
export function formatDate(time, format = 'mm-dd-YYYY') {
    if (!time) {
        return ''
    }
    const regTime = /^[0-9]+$/
    // 2021-05-17T08:53:52.697+00:00
    const regUtc = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T([0-9]{2}:){2}[0-9]{2}\.[0-9]{3}\+[0-9]{2}:[0-9]{2}$/

    if (!regTime.test(time) && !regUtc.test(time)) {
        throw new Error('time must be a unix time stamp or UTC time')
    }
    const date = new Date(time)
    let formats = [], dates = [], times = [], items
    formats = format.split(' ');
    for (let item of formats) {
        if (item.indexOf('-') !== -1) {
            dates = item.split('-')
        } else if (item.indexOf(':') !== -1) {
            times = item.split(':')
        }
    }

    if (dates.length > 0) {
        for (let i in dates) {
            let item = dates[i]
            if (item === 'dd') {
                // dates[i] = paddingZeroForNumber(date.getDate())
                format = format.replace('dd', paddingZeroForNumber(date.getDate()))
            }
            if (item === 'mm') {
                // dates[i] = paddingZeroForNumber(date.getMonth() + 1)
                format = format.replace('mm', paddingZeroForNumber(date.getMonth() + 1))

            }
            if (item === 'YY') {
                // dates[i] = date.getFullYear().toString().substring(2)
                format = format.replace('YY', date.getFullYear().toString().substring(2))

            }
            if (item === 'YYYY') {
                // dates[i] = date.getFullYear()
                format = format.replace('YYYY', date.getFullYear())

            }
        }
    }
    if (times.length > 0) {
        for (let i in times) {
            let item = times[i]
            if (item === 'h') {
                // dates[i] = paddingZeroForNumber(date.getHours())
                format = format.replace('h', paddingZeroForNumber(date.getHours()))
            }
            if (item === 'i') {
                // times[i] = paddingZeroForNumber(date.getMinutes())
                format = format.replace('i', paddingZeroForNumber(date.getMinutes()))

            }
            if (item === 's') {
                // times[i] = paddingZeroForNumber(date.getSeconds())
                format = format.replace('s', paddingZeroForNumber(date.getSeconds()))

            }
        }
    }
    return format
}
// 为数字前缀0
function paddingZeroForNumber(number) {
    if (number < 10) {
        return '0' + number
    }
    return number
}

// 四舍五入，保留精度为两位
export function roundTwo(number) {
    return Math.round(number * 100) / 100
}

// 时间转换成 几天前， 几小时前等缩写 , type: d:天，超过30天显示具体日期 h: 小时， i:分钟， s: 秒
// overDay: 超过多少天后显示具体日期
export function timeToText(time, options = {
    type: 'd',
    overDay: -1,
    format: "mm-dd-YYYY",
    textFormat: { y: 'y', m: 'M',d: 'D', h: 'H', i: 'M', s: 'S' }
}) {
    if (!time) {
        return ''
    }
    const regTime = /^[0-9]+$/
    // 2021-05-17T08:53:52.697+00:00
    const regUtc = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T([0-9]{2}:){2}[0-9]{2}\.[0-9]{3}\+[0-9]{2}:[0-9]{2}$/

    if (!regTime.test(time) && !regUtc.test(time)) {
        throw new Error('time must be a unix time stamp or UTC time')
    }
    const timeStamp = new Date(time).getTime()
    const currentTimeStamp = new Date().getTime()
    const microTime = currentTimeStamp - timeStamp
    const date = formatDate(time, options.format)
    // 秒
    const s = microTime / 1000
    const i = microTime / (60 * 1000)
    const h = microTime / (60 * 60 * 1000)
    const d = microTime / (60 * 60 * 24 * 1000)
    const m = microTime / (60 * 60 * 24 * 30 * 1000)
    const y = microTime / (60 * 60 * 24 * 365 * 1000)
    const my = Math.floor(y)
    const mm = Math.floor(m)
    const md = Math.floor(d)
    const mh = Math.floor(h)
    const mi = Math.floor(i)
    console.log(m, mm, 329)
    // 范围越大，就只显示大范围，如:foramt d,则小于一天显示0,如果是小时，则显示：多少小时，大于24小时，显示几天
    let res = {};
    let handleYear = function () {
        let res = {
            ttime: my,
            ttype: 'year',
            timeText: my + options.textFormat.y
        }
        return res
    }
    let handleMonth = function () {
        let ttype = 'month', ttime = mm, res = {
            ttime,
            ttype,
            timeText: mm + options.textFormat.m
        }
        if(m > 30) {
            res = handleYear()
        }
        return res
    }
    let handleDay = function () {
        let ttype = 'day', ttime = md, res = {
            ttime,
            ttype,
            timeText: md + options.textFormat.d
        }
        if( options.overDay > 0 && d > options.overDay) {
            res.timeText = date
            return res
        } 
        if(d > 30) {
            res = handleMonth()
        }
        console.log(res, 363)
        return res
    }
    let handleHour = function () {
        let ttype = 'hour', ttime = mh, res = {
            ttime,
            ttype,
            timeText: mh + options.textFormat.h
        }
        if (h >= 24) {
            res = handleDay()
        }
        return res
    }
    let handleMinute = function () {
        let ttype = 'minute', ttime = mi, res = {
            ttype,
            ttime,
            timeText: mi + options.textFormat.i

        }
        if (i >= 60) {
            res = handleHour()
        }
        return res
    }
    let handleSecond = function () {
        let ttype = 'second', ttime = s, res = {
            ttype,
            ttime,
            timeText: s + options.textFormat.s
        }
        if (s >= 60) {
            res = handleMinute()
        }
        return res
    }
    switch (options.type) {
        case 'd':
            res = handleDay()
            
            break;
        case 'h':
            res = handleHour()
            break;
        case 'i':
            res = handleMinute()
            break;
        case 's':
            res = handleSecond();
            break;
    }
    return res
}
