import Vuex from 'vuex'
import Vue from 'vue'
import getters from './getters'
import modules from './modules'
import {apps} from '@/config/Apps'
Vue.use(Vuex)
let globalGetters = {}
let globalState = {}
let globalActions = {}
let globalmutations = {}

for(let app in apps) {
  const store = apps[app].store
  globalGetters = {...globalGetters,  ...store.getters }
  globalState = {...globalState, ...store.state}
  globalActions = {...globalActions, ...store.actions}
  globalmutations = {...globalmutations, ...store.mutations}
}
// 主应用的getters应该覆盖子应用中同名的getter
globalGetters = {...globalGetters, ...getters}
const store = new Vuex.Store({
  modules,
  getters: globalGetters,
  actions: globalActions,
  state: globalState,
  mutations: globalmutations
})


export default store
