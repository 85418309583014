var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticStyle: { "margin-left": "20px", "margin-top": "10px" } },
      [_c("a", { on: { click: _vm.backToLogin } }, [_vm._v("← Back")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: { width: "60%", "min-width": "1000px", margin: "0 auto" },
      },
      [
        _c("h1", { staticStyle: { "text-align": "center", margin: "20px" } }, [
          _vm._v("Privacy Policy"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "faq-list" }, [
          _c("p", [
            _c("span", [
              _vm._v(
                "Thank you for visiting SstParts.com. SstParts.com is committed to protecting your privacy."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "This Privacy Policy covers any personal information that SstParts.com obtains from you when you use our services on our website. Our Privacy Policy may change in the future without notice."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Our Privacy Policy may change in the future. We invite you to check back periodically to see if we have made any changes. If we make any material changes, we will post a notice on our home page."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "By using this site, you agree to the terms of our policy. For more information about the terms of use for this site, please visit our "
              ),
              _c("a", { on: { click: _vm.toTermsOfService } }, [
                _c("strong", [_vm._v("Terms of Service")]),
              ]),
              _vm._v(" page."),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [_vm._v("What Personal Information is Collected?")]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Depending on how you use SstParts.com, we may ask you to share personal information with us. Whether you choose to give SstParts.com information is completely up to you, but keep in mind that if you withhold information, you may not be able to use some of our services."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [_vm._v("When Personal Information is Collected?")]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "SstParts.com will ask you for information, including some personal information, if you use one of several services:"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Becoming a registered user. In order to register, you need to share some personal information, such as your name, billing and shipping address, phone number, and e-mail address. You do not have to be a registered user to visit SstParts.com's web pages. But, you will have to register if you want to place orders or make full use of our services. Your personal information such as address and phone number can be viewed and changed by going to the My profile pages."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Making a purchase. When you buy something on SstParts.com, you have to supply shipping and payment details."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [
                _vm._v("When Non-Personal Information is Collected?"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "SstParts.com collects the following non-personally identifiable information: details of your visits to SstParts.com, including, but not limited to, traffic data, location data, logs, the resources that you access, and information about your computer and internet connection, including your IP address, operating system and browser type. SstParts.com may use this information for system administration, as well as optimization of the website. SstParts.com "
              ),
              _c("u", [_vm._v("does not disclose or sell")]),
              _vm._v(" this information to any third parties."),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [
                _vm._v("How SstParts.com Protects Your Information?"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "We realize that our customers trust us to protect their personal information and we take that task seriously. We maintain physical, electronic and procedural safeguards to protect your personal information."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "We urge you to protect your own privacy. We recommend that you do not share your password with anyone or share your password in an unsolicited phone call or e-mail."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Unfortunately, despite all of our efforts, there is always a risk that third parties may unlawfully intercept transmissions. This reality is true of all Internet uses. As a result, we cannot ensure the security of any information you transmit, and thus you transmit all information at your own risk."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [
                _vm._v("How SstParts.com Uses Your Personal Information?"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "SstParts.com collects personal information primarily to make our services more rewarding for you to use. We use this information to help you place orders from our webshop more easily. We may also use your information to contact you for account and promotional purposes."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [
                _vm._v(
                  "How to Avoid Receiving Newsletters and Other SstParts.com Materials?"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "We use e-mail addresses to send out newsletters and other announcements with information about SstParts.com's new products, gifts and special features. When you register, you have the option to not receive these announcements by opting-out of delivery. If you receive an e-mail and want to avoid further messages, look at the end of the e-mail for instructions on how to remove yourself from the mailing list. You can also edit your e-mail preferences at SstParts.com's My profile pages."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [_vm._v("Who Views Your Personal Information?")]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "SstParts.com may share your personal information with companies that perform services for us, such as fulfilling orders, delivering packages, sending postal mail and e-mails, analyzing customer data, providing marketing assistance, investigating fraudulent activity, conducting customer surveys, and providing customer service. They have access to personal information that is needed to perform their functions, but we may not disclose your personally identifiable information for other purposes."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Personal information may be disclosed if required by law."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "SstParts.com does not sell or license personal information. Before selling or licensing your personal information, we will post a notice in this Privacy Policy and on our home page."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("strong", [
              _c("span", [
                _vm._v(
                  "Why is CAMERA permission needed when using the Android application?"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v(
                "Because we offer Tax Exemption application, and customer is required to upload image in order for us to determine eligibility of Tax Exemption. Without the CAMERA permission, we cannot obtain any image file for the review process."
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }