var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: { scrollable: "", persistent: "" },
        model: {
          value: _vm.dialog,
          callback: function ($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog",
        },
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "card-title__cols2" }, [
            _c("div", { staticStyle: { width: "90%" } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "flex-start",
                  height: "100%",
                },
              },
              [
                _c("v-icon", { on: { click: _vm.handleClose } }, [
                  _vm._v("mdi-close"),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              staticClass: "pt8",
              style: { minHeight: _vm.$attrs["min-height"] },
              attrs: { id: _vm.$attrs["id"] },
            },
            [
              _vm._t("default"),
              _vm._v(" "),
              typeof _vm.component === "object" && _vm.dialog
                ? _c(
                    _vm.component,
                    _vm._g(
                      _vm._b(
                        {
                          tag: "component",
                          on: { "update:forms": _vm.updateForms },
                        },
                        "component",
                        _vm.$attrs["component-attrs"],
                        false
                      ),
                      {
                        ..._vm.$listeners,
                        ...(_vm.$attrs["component-events"] || {}),
                      }
                    )
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.hideActions
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm._l(_vm.btns, function (btn, i) {
                    return [
                      btn.before
                        ? _c(
                            btn.component,
                            _vm._g(
                              _vm._b(
                                {
                                  key: i,
                                  tag: "component",
                                  staticStyle: { "margin-right": "24px" },
                                },
                                "component",
                                { ...btn.attrs },
                                false
                              ),
                              { ...btn.events }
                            ),
                            [
                              _vm._v(
                                "\n          " + _vm._s(btn.name) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  !_vm.hideClose
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            outlined: "",
                            small: "",
                          },
                          on: { click: _vm.handleClose },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.closeButton) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hideConfirm
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.confirmDisabled,
                            small: "",
                          },
                          on: { click: _vm.handleConfirm },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.confirmButton) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.btns, function (btn, i) {
                    return [
                      !btn.before
                        ? _c(
                            btn.component,
                            _vm._g(
                              _vm._b(
                                {
                                  key: i,
                                  tag: "component",
                                  staticStyle: { "margin-left": "24px" },
                                },
                                "component",
                                { ...btn.attrs },
                                false
                              ),
                              { ...btn.events }
                            ),
                            [
                              _vm._v(
                                "\n          " + _vm._s(btn.name) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }