import App from './App.vue'
import router from '@/plugins/router'
import store from './store'
import instanceVuetify from '@/plugins/vuetify'
// import VueApollo from '@/plugins/apollo'
// import 'vuetify/dist/vuetify.min.css'
// import '@mdi/font/css/materialdesignicons.min.css'

import './watchDog'
// import '@mdi/font/css/materialdesignicons.min.css'
import { globalConfig } from './config/Apps'

export async function uses() {
  const u = await globalConfig.uses
  return { ...u }
}

export async function globals() {
  const g = await globalConfig.globals
  return {
    ...g,  
  }
}
export async function filters() {
  const f = await globalConfig.filters
  return {
    ...f,  
  }
}

export {router}

export async function plugins(){
  const p = await globalConfig.plugins
  // const apolloProvider = await VueApollo()
  const vuetify = await instanceVuetify()
  return {
    ...p.plugins,
    // apolloProvider,
    router,
    store,
    vuetify,
  }
}

export default {
  name: 'Bootstrap',
  render(h) {
    return h(App)
  },
}