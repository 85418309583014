const libConfig = [
    {
      name: "library",
      label: "Library",
      preload: false,
      show: false,
      entry: () => import("library/Bootstrap"),
    },
  ];
const appsConfig = [{
    name: "quickOrder",
    label: "Quick Order",
    preload: false,
    show: true,
    entry: () => import( "quickOrder/Bootstrap"),
  },];

export {appsConfig, libConfig}