import _app from './_app.js'
// import errorLog from './errorLog.js'
// import settings from './settings.js'
// import _tagsView from './tagsView.js'
import _permission from './_permission.js'
// import user from './user.js'


export default {
    _app,
    _permission,
    // _tagsView,
    // settings,
    // errorLog,
    // user
}