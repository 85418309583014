<template>
  <v-dialog v-model="dialog" scrollable persistent v-bind="$attrs">
    <v-card>
      <v-card-title class="card-title__cols2">
        <div style="width: 90%">{{ title }}</div>
        <div style="display: flex; align-items: flex-start; height: 100%">
          <v-icon @click="handleClose">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-card-text
        class="pt8"
        :id="$attrs['id']"
        :style="{ minHeight: $attrs['min-height'] }"
      >
        <slot />
        <component
          v-if="typeof component === 'object' && dialog"
          :is="component"
          v-bind="$attrs['component-attrs']"
          @update:forms="updateForms"
          v-on="{ ...$listeners, ...($attrs['component-events'] || {}) }"
        />
      </v-card-text>
      <v-card-actions v-if="!hideActions">
        <v-spacer />
        <template v-for="(btn, i) in btns">
          <component
            :key="i"
            v-if="btn.before"
            :is="btn.component"
            style="margin-right: 24px"
            v-bind="{ ...btn.attrs }"
            v-on="{ ...btn.events }"
          >
            {{ btn.name }}
          </component>
        </template>
        <v-btn v-if="!hideClose" color="secondary" outlined small @click="handleClose">
          {{ closeButton }}
        </v-btn>
        <v-btn
          v-if="!hideConfirm"
          color="primary"
          :disabled="confirmDisabled"
          small
          @click="handleConfirm"
        >
          {{ confirmButton }}
        </v-btn>
        <template v-for="(btn, i) in btns">
          <component
            :key="i"
            v-if="!btn.before"
            :is="btn.component"
            style="margin-left: 24px"
            v-bind="{ ...btn.attrs }"
            v-on="{ ...btn.events }"
          >
            {{ btn.name }}
          </component>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    component: {
      type: [Object, Boolean, Function],
      default: false,
    },
    confirmButton: {
      type: String,
      default: "Confirm",
    },
    closeButton: {
      type: String,
      default: "Cancel",
    },
    btns: {
      type: Array,
      default: () => [],
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    btnBefore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      dialogForms: {},
      confirmDisabled: false,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
      this.confirmDisabled = !val;
    },
  },
  methods: {
    updateForms(val) {
      this.dialogForms = val;
      const btn = this.$attrs["component-attrs"].btn;
      if (btn && typeof btn === "function") {
        this.confirmDisabled = btn(this.dialogForms);
      }
    },
    handleClose() {
      // if(this.defaultCloseAction) {
      // this.dialog = false;
      // }
      this.$emit("update:value", false);
      this.$emit("close", this.dialogForms);
    },
    disabledConfirmBtn() {
      this.confirmDisabled = true;
    },
    enableConfirmBtn() {
      this.confirmDisabled = false;
    },
    handleConfirm() {
      this.$emit("confirm", this.dialogForms, this.$attrs["component-attrs"]);
      this.$emit("update:value", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog__content--active {
  background: #00000050;
  pointer-events: visible;
}
.pt8 {
  padding-top: 8px !important;
}
</style>
