var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "v-app",
            [
              _c(
                "v-main",
                [
                  _c(
                    "v-app-bar",
                    {
                      staticStyle: {
                        "box-shadow": "0 1px 10px -3px rgba(0, 0, 0, 0.3)",
                        background: "#fff",
                      },
                      attrs: { dense: "", fixed: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "sk-left" },
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              type: "image",
                              width: "120",
                              height: "35",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "list-item-avatar" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sk-right" },
                        [
                          _c("v-skeleton-loader", {
                            attrs: { type: "list-item-avatar" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-navigation-drawer",
                    {
                      staticStyle: { "padding-top": "48px" },
                      attrs: { width: "200" },
                    },
                    [
                      _c("v-skeleton-loader", {
                        attrs: { type: "list-item@5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "main-content" },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "keep-alive",
                            [
                              _c("v-skeleton-loader", {
                                attrs: { type: "article@4" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : [_c("router-view")],
      _vm._v(" "),
      _c("s-scroll-to-top"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }