const getters = {
  // sidebar: state => state.app.sidebar,
  // size: state => state.app.size,
  // device: state => state.app.device,
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,
  // token: state => state.user.token,
  // avatar: state => state.user.avatar,
  // name: state => state.user.name,
  // introduction: state => state.user.introduction,
  // roles: state => state.user.roles,
  _permissionApps: state => state._permission.apps,
  _permissionRoutes: state => state._permission.routes,
  _currentRoutes: state => state._permission.currentRoutes,
  _currentApp: state => state._permission.currentApp,
  _isAppLoading: state => state._permission.isAppLoading,
  _changeAppLoading: state => state._permission.changeAppLoading,
  _userInfo: state => state._app.userInfo
  // errorLogs: state => state.errorLog.logs
}
export default getters


