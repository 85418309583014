/**
 * Author Grady
 * 说明：本文件主要进行路由及状态的加载，根据模块的配置来动态加载
 * email: shixiang@skystartrade.com
 * last updated date: 6.25.2021
 */
import router from '@/plugins/router'
import store from './store'
import { apps, __http } from './config/Apps'
import configApps from './config/Config'
import Vue from 'vue'
const __httpInstance = __http
// __httpInstance.prototype.$router = router
// 获取当前页面对应的子应用模块名称,默认为第一个
// function getModuleName(path) {
//   let keys = Object.keys(apps);
//   const permissonApps = store.getters._permissionApps;

//   keys = permissonApps.filter(app => new Set(keys).has(app.name) ? true : false)
//   const appName = keys[0] && keys[0].name || ''
//   return isIndexUrl(path) ? appName :
//     path.startsWith('/') ? path.split('/')[1] : path;
// }

function getModuleName(path) {
  let keys = Object.keys(apps);
  const permissonApps = store.getters._permissionApps;
  const alloweApps = configApps.filter(item => item.name !== 'lib').map(item => item.name)
  keys = permissonApps.filter(app => new Set(keys).has(app.name) ? true : false)
  let appName = keys[0] && keys[0].name || ''
  const appNameFromPath = path.startsWith('/') ? path.split('/')[1] : path
  appName = appName ? appName : alloweApps.includes(appNameFromPath) ? appNameFromPath : alloweApps[0]
  return appName.toLowerCase()
}

function isIndexUrl(path) {
  return path === '/' ? true : false;
}
// 给子应用的路由添加前缀，防止子系统应用的路由重复,
function getPrefixedAppRoutes(routes, moduleName, children = false) {
  for (let route of routes) {
    if (!children) {
      route.path = "/" + moduleName.toLowerCase() + route.path
      if (route.redirect) {
        if (typeof route.redirect === 'string') {
          route.redirect = "/" + moduleName.toLowerCase() + route.redirect
        }
        if (typeof route.redirect === 'object' && route.redirect.name) {
          route.redirect.name = moduleName + route.redirect.name
        }
      }
    }
    route.name = moduleName + route.name
    if (route.children) {
      getPrefixedAppRoutes(route.children, moduleName, true)
    }

  }
  return routes;
}

function getCurrentStore(moduleName) {
  return apps[moduleName] && apps[moduleName].store;
}

// 预加载路由，需要在第一次加载时加载多个模块的路由在同一个路由中，以便在第一页的菜单列表中包含多模块的路由     
async function preRegisterRoute(modules, router, store) {
  let allRoutes = []
  for (module of modules) {
    const moduleName = module.name
    if (store.getters._permissionRoutes[moduleName]) continue
    if (module.preload) {
      let moduleRoutes = await apps[moduleName].routes()
      moduleRoutes = await moduleRoutes.asyncRoutes()
      moduleRoutes = moduleRoutes.routes
      allRoutes = [...allRoutes, ...moduleRoutes]
    }
  }
  if (allRoutes.length === 0) {
    return
  }
  // console.log(allRoutes, 7171)        
  for (module of modules) {
    const moduleName = module.name
    // 注册全局状态
    if (module.preload) {
      store.dispatch('_permission/addRouters', { moduleRoutes: allRoutes, moduleName })
    }
    // store.dispatch('_permission/changeModuleRoutes', { moduleName })
    // await dynamicRegisterStore(moduleName, store, prevModuleName)
  }

  // 切换http配置
  router.addRoutes(allRoutes)
}

// 根据路由模块动态加载并注册路由模块，适用于模块较多的情况
async function dynamicRegisterRoute(moduleName, router, store) {
  const prevModuleName = store.getters._currentApp;
  let $instance = await __httpInstance();
  // 如果已经注册，则切换状态
  if (store.getters._permissionRoutes[moduleName]) {
    // 注册全局状态
    store.dispatch('_permission/changeModuleRoutes', { moduleName })
    await dynamicRegisterStore(moduleName, store, prevModuleName)
    // 切换http配置
    Vue.prototype.$http = await apps[moduleName].httpConfig($instance)

    return
  }

  console.log('100', apps[moduleName], moduleName)

  if (!apps[moduleName]) {
    console.log('101', apps[moduleName], moduleName)
    throw new Error('No app can access or config')
  }
  // // 如果路由已经注册，则不再去注册,直接切换即可
  // if (store.getters._permissionRoutes[moduleName]) {
  //   store.dispatch('_permission/changeModuleRoutes', { moduleName })
  //   return
  // }
  let moduleRoutes = await apps[moduleName].routes()
  // console.log(moduleRoutes, moduleName, 76)
  moduleRoutes = await moduleRoutes.asyncRoutes()
  // console.log(moduleRoutes,moduleName, 78)
  moduleRoutes = moduleRoutes.routes
  // 注册全局状态
  store.dispatch('_permission/addRouters', { moduleRoutes, moduleName })
  store.dispatch('_permission/changeModuleRoutes', { moduleName })
  await dynamicRegisterStore(moduleName, store, prevModuleName)

  // 切换http配置
  Vue.prototype.$http = await apps[moduleName].httpConfig($instance)
  router.addRoutes(moduleRoutes)

}


// 初始化store，根据应用来动态切换Store
async function dynamicRegisterStore(newModuleName, store, oldModuleName) {
  if (!store.state._permission.registeredStores[newModuleName]) {
    const { modules } = await getCurrentStore(newModuleName)();
    store.dispatch('_permission/registerStore', { moduleName: newModuleName, modules });
  }
  const pendingRegisterModules = store.state._permission.registeredStores[newModuleName];
  for (let key in pendingRegisterModules) {
    const module = pendingRegisterModules[key]

    // console.log(key, module, !store.hasModule(key))
    !store.hasModule(key) && store.registerModule(key, module)
  }
  // debugger
}

// 获取并注册所有子应用
async function registerApps(moduleName, path) {
  if (store.state._permission.apps.map(app => app.name).includes(moduleName)) {
    return store.state._permission.apps.map(app => app.name);
  }
  // 第二期再开通动态加载应用的逻辑
  // let $http = await http();
  // 登录授权步骤TODO
  // let { error, data } = await $http.get(getApps, null, { loading: false })
  // 返回数据示例
  // const data = [
  //   {
  //     name: 'productCenter',
  //     path: '/productcenter'
  //   },
  //   // {
  //   //   name: 'app2',
  //   //   path: '/app2'
  //   // },
  //   {
  //     name: 'lib',
  //     path: '/lib'
  //   },
  // ]
  // const permissionApps = data.filter(app => apps[app.name] ? true : false)
  // store.dispatch('_permission/addApps', { modules: permissionApps })

  // 需要接口支持返回登录字段 TODO， path可以通过全局环境变量去拿，配置在全局config中
  // 根据实际情况添加判断，下面仅是示例
  // if (!data) {
  //   router.push('login');
  //   return;
  // }


  // 不需要授权的应用，直接把配置中的拿 过来即可
  const permissionApps = Object.keys(apps).map(appName => { return { name: appName } })
  // 注册有权限的子应用
  store.dispatch('_permission/addApps', { modules: permissionApps })

  return permissionApps.map(item => item.name)
}

// 开始切换应用时的加载状态
function startAppChangeLoading() {
  store.dispatch('_permission/setChangeAppLoading', { loading: true })
}
// 结束切换应用时的加载状态
function endAppChangeLoading() {
  store.dispatch('_permission/setChangeAppLoading', { loading: false })
}


// 重新加载整个应用时加载状态
function startLoading() {
  store.dispatch('_permission/setAppLoading', { loading: true })
}
// 结束整个应用加载状态
function endLoading() {
  store.dispatch('_permission/setAppLoading', { loading: false })
}
startLoading()
const loginUrl = _ENV_.SOURCE.LOGIN_URL
const enviroment = _ENV_.SOURCE.NODE_ENV
router.beforeEach(async (to, from, next) => {
  const toPath = to.fullPath.toLowerCase()
  let moduleName = getModuleName(toPath) || ''
  // 如果本地没有授权，则跳转去登录 
  if (!localStorage.getItem('auth-token')) {
   
    if (!toPath.startsWith('/login')) {
      next('/login?returnUrl=' + toPath)
    } else {
      endLoading()
      next(true)
    }
  } else {
    const userInfo = JSON.parse(localStorage.getItem('auth-token')).user_info
  
    store.dispatch('_app/setUserInfo', userInfo)
    // access permission Apps, if no login will redirect login page
    if (store.getters._currentApp !== null && moduleName === store.getters._currentApp.toLowerCase()) {
      if (to.matched.length > 0) {
        next()
      } else {
        router.replace(store.getters._currentRoutes[0].path)
      }
    } else {
      if (!store.getters._currentApp || store.getters._currentApp.toLowerCase !== moduleName) {

        // 切换应用加载一定是整个主应用加载完成后才启用,切换应用状态
        // if (!store.getters._changeAppLoading && toPath === '/' + moduleName.toLowerCase()) {
        startAppChangeLoading()
        // }
        const registeredApps = await registerApps(moduleName, location.href)
        // const registeredApps = ['library', 'productCenter']
        // if no apps allowed access , show some messages
        if (registeredApps.length === 0) {
          console.warn('no apps allowed access')
          return next(false)
        }
        if (!registeredApps.includes(moduleName)) {
          moduleName = registeredApps[0]
        }
        // 注册需要预加载的路由

        await preRegisterRoute(configApps, router, store)
        await dynamicRegisterRoute(moduleName, router, store)
        //  console.log(registeredApps, moduleName, 251)


        store.getters._isAppLoading && endLoading()
        endAppChangeLoading();
        if (toPath.toLowerCase().indexOf(moduleName.toLowerCase()) === -1) {
          router.replace(store.getters._currentRoutes[0].path)
        } else {
          router.replace(toPath)
        }
      } else {
        // console.log(toPath, moduleName,store.getters,  242)
        // if(toPath == '/lib' || toPath == '/productcenter') {
        //   next()
        // }
        // console.log(to, store.getters, moduleName, 257)
        if (to.matched.length > 0) {
          next()
        } else {
          router.push(store.getters._currentRoutes[0].path)
        }
      }
    }
  }
})

// 当路由发生错误时重新刷新页面
router.onError((error, to, from) => {
  location.reload()
})