var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticStyle: { "padding-top": "50px" },
      attrs: { color: _vm.type, top: "", timeout: _vm.timeout },
      on: { input: _vm.handleInput },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.closeMessage } },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c("v-icon", { attrs: { size: "14" } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c("v-icon", [
        _vm._v(
          _vm._s(
            _vm.type === "info"
              ? "mdi-check-circle-outline"
              : "mdi-alert-circle-outline"
          )
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }