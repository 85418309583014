// import { constantRoutes } from '@/router'

// import {apps} from '@/config/Apps'



/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
// function hasPermission(roles, route) {
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.includes(role))
//   } else {
//     return true
//   }
// }

// console.log(asyncRouterMap, constantRoutes)

function hasPermission (remote, localRouters) {
  return localRouters.filter(local => {
    return local.name === remote.name
  })[0]
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRouterMap
 * @param roles
 */
export function toTree (remoteRouters, localRouters) {
  const res = []
  remoteRouters.forEach(remote => {
    let _item = hasPermission(remote, localRouters)
   
    if (_item) {
      if (_item.children) {
        let _child = toTree(remote.children, _item.children)
        _item.children = _child
      }
      res.push(_item)
    }
  })

  return res
}



const state = {
  routes: {},
  registeredStores: {
  },
  currentApp: null,
  // addRoutes: null,
  currentRoutes: null,
  apps: [],
  isAppLoading: false,
  changeAppLoading: false
}

const mutations = {
  SET_ROUTES: (state, {routes, moduleName}) => {
    // state.addRoutes = routes
    state.routes[moduleName] = routes
  },
  SET_CURRENT_ROUTES: (state, {moduleName, routes}) => {
    state.currentApp = moduleName
    state.currentRoutes =  routes || state.routes[moduleName];
  },
  SET_CURRENT_STORES: (state, {moduleName, modules}) => {
    state.registeredStores = {...state.registeredStores[moduleName], [moduleName]: modules}
  },
  SET_APPS: (state, {modules}) => {
    state.apps = [...modules]
  },
  SET_APP_LOADING: (state, { loading }) => {
    state.isAppLoading = loading
  },
  SET_CHANGE_APP_LOADING: (state, { loading }) => {
    state.changeAppLoading = loading
  }
}
const actions = {
  addRouters ({commit}, payload) {
    const {moduleRoutes, moduleName} = payload
    if( moduleRoutes ) {
        commit('SET_ROUTES', {routes: moduleRoutes, moduleName})
    }
    return []
  },
  changeModuleRoutes({commit}, moduleName) {
    commit('SET_CURRENT_ROUTES', moduleName)
  },
  registerStore({commit}, payload) {
    commit('SET_CURRENT_STORES', payload)
  },
  addApps ({commit}, apps) {
    commit('SET_APPS', apps)
  },
  setAppLoading({commit}, loading){
    commit('SET_APP_LOADING', loading)
  },
  setChangeAppLoading({commit}, loading){
    commit('SET_CHANGE_APP_LOADING', loading)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
