<template>
  <div>
    <v-app v-if="isLoading">
      <v-main>
        <v-app-bar
          dense
          fixed
          style="
            box-shadow: 0 1px 10px -3px rgba(0, 0, 0, 0.3);
            background: #fff;
          "
        >
          <div class="sk-left">
            <v-skeleton-loader type="image" width="120" height="35" />
          </div>
          <div>
            <v-skeleton-loader type="list-item-avatar" />
          </div>
          <div class="sk-right">
            <v-skeleton-loader type="list-item-avatar" />
          </div>
        </v-app-bar>
        <v-navigation-drawer width="200" style="padding-top: 48px">
          <v-skeleton-loader type="list-item@5" />
        </v-navigation-drawer>
        <div class="main-content">
          <v-container>
            <keep-alive>
              <v-skeleton-loader type="article@4" />
            </keep-alive>
          </v-container>
        </div>
      </v-main>

      <!-- </div> -->
      <!-- <v-footer>this is footer</v-footer> -->
    </v-app>
    <template v-else>
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </template>
    <s-scroll-to-top />
  </div>
</template> 
<script>
import { mapState } from "vuex";
const SLibrary = () => import("library/Component");

export default {
  name: "App",
  components: {
    SScrollToTop: async function () {
      const lib = await SLibrary();
      return lib.component.SScrollToTop;
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("_permission", {
      isLoading: (state) => state.isAppLoading,
    }),
  },
  mounted () {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
    window.addEventListener('hashchange', () => {
      let currentPath = window.location.hash.slice(1)
      if (this.$route.path !== currentPath) {
        this.$router.replace(currentPath)
      }
    },false)
  },
};
</script>
<style lang="scss" scoped>
.sk-left,
.sk-right,
.sk-center {
  display: inline-block;
}
.sk-left {
  width: 130px;
}
.sk-right {
  width: calc(100% - 200px);
}
.sk-right > div {
  float: right;
  // text-align: right;
}

.main-content {
  width: calc(100% - 200px);
  position: absolute;
  left: 200px;
  top: 0px;
  padding-top: 48px;
  height: 100%;
  .container {
    max-width: 100%;
  }
}
.logo-wraper {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  // width: 180px;
  // transition: all 0.5s;

  .logo {
    width: 136px;
    // flex-shrink: 0;
    transition: all 0.2s;
  }

  &.collapse {
    overflow: hidden;
    .logo {
      width: 0;
      overflow: hidden;
      transition: all 0.2s;
    }
  }
}
</style>